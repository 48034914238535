import React, { useState } from "react";
import {
  GetUserEntity,
  GetUserProfile,
  PostLoginAsUserReturn,
} from "../../types/types";
import { config } from "../../config/config";
import { Copy, LogIn, Mic, Sparkles, SquareCheckBig } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Button } from "../ui/button";
import PasswordModal from "./PasswordModal";
import { usePostLoginAsUser } from "../../query/useUser";
import { toast } from "sonner";
import { AxiosError } from "axios";

const Overview = ({
  profile,
  entity,
  first_name,
  last_name,
  mail,
  id,
  type,
}: {
  profile: GetUserProfile;
  entity: GetUserEntity;
  first_name: string | null;
  last_name: string | null;
  mail: string;
  id: number;
  type: string;
}) => {
  const baseUrl = config.apiUrl;
  const [password, setPassword] = useState<string>("");
  const [isOpenAddPassword, setIsOpenAddPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");

  const { mutate: loginAsUser, isPending: loginAsUserLoading } =
    usePostLoginAsUser();

  const handleLoginAsUser = () => {
    loginAsUser(
      {
        pk: id,
        password: password,
      },
      {
        onSuccess: (res: PostLoginAsUserReturn) => {
          window.open(
            res.user_dashboard_url + "/sign-in?token=" + res.encrypted_token
          );
          setIsOpenAddPassword(false);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.message) {
              setPasswordError(err.response.data.message);
            } else if (err.response.data.message.password) {
              setPasswordError(err.response.data.message.password[0]);
            } else {
              setPasswordError("Something went wrong");
            }
          }
        },
      }
    );
  };

  const handleCopyMagicLink = () => {
    try {
      navigator.clipboard.writeText(
        "https://magicreview.ai/" + entity?.magic_link
      );
      toast.success("Copied to clipboard");
    } catch (e) {
      toast.error("Error copy");
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-x-5 py-5 px-5 lg:px-10">
          <img
            src={profile?.profile_picture || "/magicreview.svg"}
            alt=""
            className="w-[45px] h-[45px] rounded-full flex-shrink-0"
          />
          <p className="font-medium text-[22px]">
            {first_name + " " + last_name}
          </p>
          <div>
            {type === "Normal User" && (
              <div className="w-[90px] h-6 rounded-[7px] bg-[#affebf] text-[#014b40] text-xs text-center flex justify-center items-center capitalize">
                {type}
              </div>
            )}
            {type === "Master User" && (
              <div className="w-[90px] h-6 rounded-[7px] text-[#8e0b21] bg-[#fed1d7] text-xs text-center flex justify-center items-center capitalize">
                {type}
              </div>
            )}
            {type === "Sub User" && (
              <div className="w-[90px] h-6 rounded-[7px] bg-[#ffd6a4] text-[#5e4200] text-xs text-center flex justify-center items-center capitalize">
                {type}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-3">
          <Button onClick={() => setIsOpenAddPassword(true)} className="gap-2">
            <LogIn className="size-4" />
            Login as User
          </Button>
          <Button className="gap-2" onClick={handleCopyMagicLink}>
            <Copy className="size-4" />
            MagicLink
          </Button>
        </div>
      </div>
      <hr className="mb-6" />
      <div className="rounded-lg lg:rounded-2xl border bg-white">
        <Table>
          <TableHeader>
            <TableRow className="text-[#888] font-medium font-pro">
              <TableHead></TableHead>
              <TableHead>Description</TableHead>
              <TableHead>No. of Reviews</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow className={`font-pro`}>
              <TableCell>
                <Sparkles className="text-[#4869DF]" />
              </TableCell>
              <TableCell>AI Review Generated</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
            <TableRow className={`font-pro`}>
              <TableCell>
                <SquareCheckBig className="text-[#4869DF]" />
              </TableCell>
              <TableCell>AI Review Redirected</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
            <TableRow className={`font-pro`}>
              <TableCell>
                <Mic className="text-[#4869DF]" />
              </TableCell>
              <TableCell>Voice Review Posted</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <PasswordModal
        isOpenDialog={isOpenAddPassword}
        setIsOpenDialog={setIsOpenAddPassword}
        confirmAction={handleLoginAsUser}
        isLoading={loginAsUserLoading}
        value={password}
        setValue={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
      />
    </>
  );
};

export default Overview;
